import { render, staticRenderFns } from "./IconHeader.vue?vue&type=template&id=72c114c4&scoped=true"
import script from "./IconHeader.vue?vue&type=script&lang=js"
export * from "./IconHeader.vue?vue&type=script&lang=js"
import style0 from "./IconHeader.vue?vue&type=style&index=0&id=72c114c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c114c4",
  null
  
)

export default component.exports