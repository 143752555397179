var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('NavPage',[_c('IconHeader',{attrs:{"major":true,"text":"Settings","icon":"gear","icon-colour":"var(--primary-colour)"}}),_c('IconHeader',{attrs:{"text":"Trials","icon":"list"}}),_c('div',{staticClass:"control-row faint-border-bottom"},[_c('p',[_vm._v("How many "),_c('strong',[_vm._v("words and sentences")]),_vm._v(" would you like to practice?")]),_c('div',{staticClass:"control"},[_c('ShowCurrentSelector',{attrs:{"options":[
            { label:  '5 words', val:  5, },
            { label: '10 words', val: 10, },
            { label: '15 words', val: 15, },
            { label: '20 words', val: 20, },
            { label: '25 words', val: 25, },
            { label: '30 words', val: 30, },
            { label: '35 words', val: 35, },
            { label: '40 words', val: 40, },
            { label: '45 words', val: 45, },
            { label: '50 words', val: 50, } ],"initial-value":_vm.wordsPerSession},on:{"update":function($event){_vm.wordsPerSession = $event}}})],1)]),_c('IconHeader',{attrs:{"text":"Timer","icon":"stopwatch"}}),_c('div',{staticClass:"control-row faint-border-bottom"},[_c('p',[_vm._v("For how many "),_c('strong',[_vm._v("seconds")]),_vm._v(" would you like each word or sentence to be shown?")]),_c('div',{staticClass:"control"},[_c('ShowCurrentSelector',{attrs:{"options":[
            { label: 'Always shown', val:  0, },
            { label:    '5 seconds', val:  5, },
            { label:   '10 seconds', val: 10, },
            { label:   '15 seconds', val: 15, },
            { label:   '20 seconds', val: 20, },
            { label:   '25 seconds', val: 25, },
            { label:   '30 seconds', val: 30, } ],"initial-value":_vm.wordDisplayTime},on:{"update":function($event){_vm.wordDisplayTime = $event}}})],1)]),_c('IconHeader',{attrs:{"text":"Assistance level","icon":"check"}}),_c('div',{staticClass:"control-row faint-border-bottom"},[_c('div',[_c('p',[_vm._v("How much "),_c('strong',[_vm._v("help")]),_vm._v(" would you like?")]),_c('p',[_vm._v(_vm._s(_vm.assistanceLevelDescriptions[_vm.assistanceLevel]))])]),_c('div',{staticClass:"control"},[_c('ShowAllSelector',{attrs:{"options":[
            { label: 'Max.', val: 'MAX', },
            { label: 'Min.', val: 'MIN', },
            { label: 'None', val: 'NONE', } ],"active-value":_vm.assistanceLevel},on:{"update":function($event){_vm.assistanceLevel = $event}}})],1)]),_c('IconHeader',{attrs:{"text":"Words or sentences","icon":"comments"}}),_c('div',{staticClass:"control-row faint-border-bottom"},[_c('div',[_c('p',[_vm._v("Would you like to practise "),_c('strong',[_vm._v("words")]),_vm._v(", "),_c('strong',[_vm._v("sentences")]),_vm._v(", or a "),_c('strong',[_vm._v("mix of both")]),_vm._v("?")]),_c('p',[_vm._v("This selection applies to the "),_c('strong',[_vm._v("default categories only")]),_vm._v(". Custom categories will include "),_c('strong',[_vm._v("all of their words and sentences")]),_vm._v(" regardless of this setting.")])]),_c('div',{staticClass:"control"},[_c('ShowAllSelector',{attrs:{"options":[
            { label: 'Words', val: 'WORDS', },
            { label: 'Sentences', val: 'SENTENCES', },
            { label: 'Words and sentences', val: 'BOTH', } ],"active-value":_vm.wordsSentences},on:{"update":function($event){_vm.wordsSentences = $event}}})],1)]),_c('IconHeader',{attrs:{"text":"Repetitions","icon":"repeat"}}),_c('div',{staticClass:"control-row faint-border-bottom"},[_c('p',[_c('strong',[_vm._v("How many times")]),_vm._v(" would you like to practice each word or sentence?")]),_c('div',{staticClass:"control"},[_c('ShowAllSelector',{attrs:{"options":[
            { label: '1', val: 1, },
            { label: '3', val: 3, } ],"active-value":_vm.wordRepetitions},on:{"update":function($event){_vm.wordRepetitions = $event}}})],1)]),_c('IconHeader',{attrs:{"text":"Capitalization","icon":"font"}}),_c('div',{staticClass:"control-row faint-border-bottom"},[_c('p',[_vm._v("How would you like the words and keyboard to appear?")]),_c('div',{staticClass:"control"},[_c('ShowAllSelector',{attrs:{"options":[
            { label: 'UPPERCASE', val: 'UPPERCASE', },
            { label: 'lowercase', val: 'LOWERCASE', } ],"active-value":_vm.wordDisplayCapitalization},on:{"update":function($event){_vm.wordDisplayCapitalization = $event}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }